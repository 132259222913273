<template>
	<v-row align="center" justify="start">
		<v-col cols="6" md="8" sm="12" class="caption mt-8">
			<!-- Truck Information -->
			<template v-for="truck in truckFields">
				<v-row :key="truck.name">
					<v-col cols="2" md="3" sm="4" class="text-end blue-grey--text font-weight-bold py-0">
						<div class="pt-3">{{ truck.name }}</div>
					</v-col>
					<v-col cols="10" md="9" sm="8" class="py-0">
						<FormFieldText v-model="truckData[truck.id]" :placeholder="truck.placeholder"></FormFieldText>
					</v-col>
				</v-row>
			</template>

			<!-- optional fields -->
			<v-row align="center" justify="start" class="pl-2 mt-4">
				<v-col cols="3">
					<v-btn class="ma-0 caption font-weight-bold" tile dark color="grey lighten-1">Hide Optional Fields</v-btn>
				</v-col>
			</v-row>
			<v-row align="center" justify="start" class="mt-3">
				<v-col cols="12">
					<!-- Optional fields -->
					<template v-for="optional in truckFieldsOptional">
						<v-row :key="optional.id" class="caption">
							<v-col cols="3" md="4" sm="4" class="text-end blue-grey--text font-weight-bold py-0">
								<div class="pt-2 font-weight-bold">{{ optional.name }}</div>
							</v-col>
							<v-col cols="9" md="8" sm="8" class="py-0">
								<FormFieldText v-model="truckData.optionalFields[optional.id]" :placeholder="optional.name"></FormFieldText>
							</v-col>
						</v-row>
					</template>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<h3 class="pl-2 blue-grey--text text--darken-4">Initial Location</h3>
					<div class="mt-3 ml-10 pl-10">
						<v-alert class="font-weight-bold caption" dense text color="blue darken-2">
							The initial location of the unit is used to accurately calculate mileage.
						</v-alert>
					</div>
				</v-col>
			</v-row>
			<!-- Initial Location -->
			<template v-for="location in initialLocationFields">
				<v-row :key="location.id" class="caption">
					<v-col cols="3" md="4" sm="4" class="text-end blue-grey--text font-weight-bold py-0">
						<div class="pt-2 body-1 font-weight-bold">{{ location.name }}</div>
					</v-col>
					<v-col cols="9" md="8" sm="8" class="py-0">
						<FormFieldText
							v-model="truckData[location.id]"
							:placeholder="location.name"
							:hint="location.hint"
							:persistentHint="location.persistentHint"
						></FormFieldText>
					</v-col>
				</v-row>
			</template>
			<v-row align="center" justify="start" class="pl-2 mt-4">
				<v-col cols="4" class="text-right">
					<v-btn class="ma-0 caption font-weight-bold" tile dark color="grey lighten-1">
						<v-icon dark> mdi-plus</v-icon>
						Create Initial Location
					</v-btn>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import FormFieldText from "Components/FormFields/FormFieldText";
export default {
	name: "LoadBoardAddTruck",
	components: {
		FormFieldText
	},
	props: {
		truckInfo: {
			type: Object,
			required: true
		},
		truckFields: {
			type: Array,
			required: true
		},
		truckFieldsOptional: {
			type: Array,
			required: true
		},
		initialLocationFields: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			name: "",
			truckData: { ...this.truckInfo }
		};
	}
};
</script>
