<template>
	<v-col cols="12">
		<v-card elevation="0" class="pl-5 pr-5 mt-2" color="transparent">
			<v-row>
				<v-col>
					<v-card elevation="0" class="mb-5" color="transparent">
						<v-card-title class="button font-weight-medium pa-0 blue-grey--text text--darken-4">
							LoadBoard
							<span class="ml-1 warning--text font-weight-bold">Add Truck</span>
						</v-card-title>
						<v-card-title class="mt-3 pb-0">
							<v-spacer></v-spacer>
							<v-btn class="ma-0 caption font-weight-bold mr-2" tile dark color="warning" width="100">Save</v-btn>
							<v-btn class="ma-0 caption font-weight-bold mr-2" tile dark color="warning" width="100">Add New</v-btn>
							<v-btn class="ma-0 caption font-weight-bold" tile dark color="warning" width="100">Cancel</v-btn>
						</v-card-title>
					</v-card>
				</v-col>
			</v-row>
			<v-card elevation="1" class="pl-0 pr-0 pt-2 pb-10">
				<v-container fill-height>
					<!-- page icon -->
					<PageIcon :background-color="pageIconBackgroundColor">
						<v-icon x-large>mdi-truck-delivery-outline</v-icon>
					</PageIcon>
					<form action="" class="container">
						<AddTruck
							ref="loadboard-add-truck"
							:truckInfo="truckInfo"
							:truckFields="truckFields"
							:truckFieldsOptional="truckFieldsOptional"
							:initialLocationFields="initialLocationFields"
						></AddTruck>
					</form>
				</v-container>
			</v-card>
		</v-card>
	</v-col>
</template>

<script>
// COMPONENTS
const AddTruck = () => import(/* webpackChunkName: 'loadboard add truck'*/ "Components/loadboard/LoadboardAddTruck");
import PageIcon from "Components/UtilityUI/UtilityUIPageIcon";
export default {
	name: "CustomerLoadboardAddTruck",
	components: {
		AddTruck,
		PageIcon
	},
	data() {
		return {
			pageIconBackgroundColor: "pink",
			truckInfo: {
				truckOwner: "",
				truckType: "",
				loadCapacity: "",
				optionalFields: {
					vim: "",
					make: "",
					model: "",
					description: "",
					year: "",
					yearPurchased: "",
					purchasePrice: "",
					licensePlateExpiration: "",
					inspectionStickerExpiration: ""
				},
				initialLocation: ""
			},
			truckFields: [
				{
					id: "truckOwner",
					name: "Truck Owner",
					type: "text",
					placeholder: "Truck Owner"
				},
				{
					id: "truckType",
					name: "Truck Type",
					type: "text",
					placeholder: "Truck Type"
				},
				{
					id: "loadCapacity",
					name: "Load Capacity",
					type: "text",
					placeholder: "Load Capacity"
				},
				{
					id: "loadingSpace",
					name: "Loading Space",
					type: "text",
					placeholder: "Loading Space"
				}
			],
			truckFieldsOptional: [
				{
					id: "vim",
					name: "VIM",
					type: "text",
					placeholder: "VIM"
				},
				{
					id: "make",
					name: "Make",
					type: "text",
					placeholder: "Make"
				},
				{
					id: "model",
					name: "Model",
					type: "text",
					placeholder: "Model"
				},
				{
					id: "description",
					name: "Description",
					type: "text",
					placeholder: "Description"
				},
				{
					id: "year",
					name: "Year",
					type: "text",
					placeholder: "Year"
				},
				{
					id: "yearPuchased",
					name: "Year Purchased",
					type: "text",
					placeholder: "Year Purchased"
				},
				{
					id: "purchasePrice",
					name: "Purchase Price",
					type: "text",
					placeholder: "Purchase Price"
				},
				{
					id: "licensePlateExpiration",
					name: "License Plate Expiration",
					type: "text",
					placeholder: "License Plate Expiration"
				},
				{
					id: "inspectionStickerExpiration",
					name: "Inspection Sticker Expiration",
					type: "text",
					placeholder: "Inspection Sticker Expiration"
				}
			],
			initialLocationFields: [
				{
					id: "initialLocation",
					name: "Initial Location",
					type: "text",
					placeholder: "Initial Location",
					hint: "Search for name, street, city, or state by typing in the box.",
					persistentHint: true
				}
			]
		};
	}
};
</script>

<style scoped>
</style>